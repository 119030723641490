<template>
    <div class="doctor-msg">
        <ul>
            <!-- 是不是管理员，才可以删除和添加  可以退出会诊 -->
            <li v-for="(item,index) in list" v-if="item.member_type == 2" >{{item.username}}
                <img v-if="item.type == 2" 
                class="icon_c" 
                src="../../assets/image/cancel.png" 
                alt=""
                @click.stop="deleteDoctor(item)">
            </li>
        </ul>
    </div>
</template>
<script>
import { ConsultMember } from "../../api/api";
import { ExitConsult } from "../../api/api";
import { scrypt } from "crypto";
export default {
    components:{
    },
    props: {
        doctorObj:Object,//必要参数。咨询consult_id
    },
    data() {
        return {
            list:[],
        };
    },
    mounted() {
        if(this.doctorObj.consult_id != undefined && this.doctorObj.consult_id != "" && this.doctorObj.consult_id != null){
            this.getList();
        }
    },
    methods:{
        getList(){
            this.$axios.post(ConsultMember,{'consult_id': this.doctorObj.consult_id}).then(res => {
                if(res.data.code == 1){
                    this.list =  res.data.data;
                }else{
                    this.list = [];
                }
            }, err => {
            });
        },
        deleteDoctor(item){
            var that = this;
            this.$confirm('确定要移除成员吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var db = {
                        'consult_id': that.doctorObj.consult_id,
                        'doctor_info_id': item.member_id,
                    };
                    that.$axios.post(ExitConsult,db).then(res => {
                        if(res.data.code == 1){
                            that.$message.error('移除成员成功');
                            that.getList();
                        }else{
                            that.$message.error('移除成员失败');
                        }
                    }, err => {
                    });
                }).catch(() => {   
                });
        },
    },
}
</script>
<style lang="scss">
.doctor-msg{
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    ul{
        border-top: 1px solid #ddd;
        border-left: 0px;
    }
    li{
        display: inline-block;
        padding: 10px 20px;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        .icon_c{
            position: absolute;
            top: 5px;
            right:5px;
            width:15px;
            height: 15px;
        }
        .icon_a{
            width:20px;
            height: 20px;
        }
    }
}
</style>
