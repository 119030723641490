<template>
    <el-dialog
        title="推送量表"
        :visible="modalVisible"
        width="480px"
        top="20%"
        @close="closeModal"
        @open="openModal"
        >
        <div style="padding:30px 60px 30px">
            <el-form label-width="80px" class="demo-ruleForm">
                <el-row>
                    <el-checkbox-group v-model="checkedlb" @change="changeLb" style="max-height: 350px;overflow-y: auto;">
                        <el-checkbox v-for="(item,index) in scaleList" :label="item" :key="item.title" style="margin-bottom:10px">{{item.question_title}}</el-checkbox>
                    </el-checkbox-group>
                    <span v-if="checkedlbText" style="color:orange">量表不能为空，请选择</span>
                </el-row>
            </el-form>
        </div>
        <!-- 按钮 -->
        <div class="account-footer clearfix">
            <div><el-button @click="closeModal">取 消</el-button></div>
            <div><el-button type="primary" @click="lbConfirmChat">确 定</el-button></div>
        </div>
    </el-dialog>
</template>
<script>
import { ScaleListChat } from "../../api/api";
import { scrypt } from "crypto";
export default {
    components:{
    },
    props: {
        modalVisible:Boolean,//是否显示
    },
    data() {
        return {
            checkedlb:[],//被选中的量表值
            checkedlbText:false,//量表错误提示
            scaleList:[],//量表-列表
        };
    },
    mounted() {
    },
    methods:{
        //打开弹框  
        openModal(){
            this.getMsgList();
        },
        //获取量表
        getMsgList(){
            var that = this;
            this.$axios.post(ScaleListChat,{}).then(res => {
                if(res.data.code == 1){
                    var info = res.data.data;
                    if(info.length > 0){ 
                        this.scaleList = info;
                    }else{
                        this.scaleList = [];
                    }                        
                }else{
                    this.scaleList = [];
                }
                    
            }, err => {
            });
        },  
        //选中量表
        changeLb(val){
            this.checkedlb = val;
            if(this.checkedlb.length > 0){
                this.checkedlbText = false;
            }
        },
        //量表发送消息
        lbConfirmChat(){
            if(this.checkedlb.length == 0){
                this.checkedlbText = true;
            }else {
                this.checkedlbText = false;

                for(var i = 0; i < this.checkedlb.length; i++){
                    var db = {
                        message_type : 4,
                        inputValue : this.checkedlb[i].question_title,
                        foreign_id : this.checkedlb[i].question_id,
                    };
                    this.$emit('successLB',db)
                    if (i == this.checkedlb.length - 1) {
                        //表明是最后一个量表
                        this.closeModal();
                        this.checkedlb = [];
                    }
                };
            }
        },
        //关闭弹框
        closeModal(){
            this.$emit('closeModal',false)
        },
    },
}
</script>
<style lang="scss">
</style>
