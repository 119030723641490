<template>
  <div class="test-detail">
    <!-- 测评历史-已推送、未推送弹框-->
    <el-dialog
      v-loading="loading"
      :title="dialogTitle"
      :visible="modalVisible"
      width="1000px"
      top="20%"
      :before-close="handleClose"
    >
      <div style="padding:20px 30px 0">
        <el-row>
          <el-col :span="24">
            <div class="title-all-bg c06 font-s16" style="margin: 0px 0px 0px;">基本信息</div>
          </el-col>
        </el-row>
        <el-row v-if="data.userinfo.length>0">
          <el-col v-for="(item,index) in data.userinfo" :key="index" :span="8" style="padding-top:10px">
            <div>
              <span class="span-title">{{ item.name }}</span>
              <span class="span-text">{{ item.value }} </span>
            </div>
          </el-col>
        </el-row>
        <!-- 测评结果内容  start-->
        <div class="scale-result">
          <div>
            <div v-for="(item,index) in data.lines" :key="index" class="tab">
              <h5 class="title-all-bg c06 font-s16">{{ item.title }}</h5>
              <div v-if="item.column.length > 0 && item.column!=undefined" class="tabTitle  c06  flex-start">
                <div v-for="(itm,index2) in item.column" :key="index2" :style="'width:'+100/item.column.length+'%'">{{ itm }}</div>
              </div>
              <template v-if="item.lines.length > 0 && item.lines!==undefined">
                <div v-for="(itm,index2) in item.lines" :key="index2" class="tabList borderee  flex-start">
                  <div v-for="(valindex,index3) in itm" :key="index3" :style="'width:'+100/item.column.length+'%'">{{ valindex.val }}</div>
                </div>
              </template>
            </div>
          </div>
          <!-- 评估说明 -->
          <!-- 评估建议 -->
          <div v-if="dbs.length>0" class="explain bg-ff">
            <div v-for="(item,index) in dbs" :key="index">
              <p v-if="item.title!=='评估说明'" class="p-title">{{ item.title }}：</p>
              <p v-if="item.title==='评估说明'" class="p-title p-star ">
                {{ item.title }}：
              </p>
              <div v-for="(itm,index2) in item.db" :key="index2" style="padding:0px">
                <div class="font-s28 c99" style="padding:5px 0">{{ itm.title }}</div>
                <span v-if="item.img_flag == false" class="font-s28 c333">
                  <div v-if="item.title!='评估说明' && item.title!='评估建议'" class="p-t-10 p-b-10">
                    {{ itm.state }}
                  </div>
                  <div v-if="item.title==='评估说明' || item.title==='评估建议'">
                    <div class="edit_container">
                      {{ itm.state }}
                    </div>
                  </div>
                </span>
                <img v-if="item.img_flag == true" :src="itm.state" style="height:70%;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ScalerInfo } from '../../api/api'
export default {
  data() {
    return {
      modalInfo:'',
      modalVisible:false,
      loading: false,
      row: {}, // 传递的数据
      data: {
        dbs:[],
        userinfo:{},
      },
      dialogTitle: '',
      dbs: [{db:''}], // 评估建议 、评估说明
      hint: ''// 提示
    }
  },
  computed: {
  },
  watch: {
  },
  mounted(){
  },
  methods: {
    detail() { // 详情
      var db = {
        id: this.row.records_id
      }
      this.$axios.post(ScalerInfo, db).then(
        (res) => {
          if (res.data.code == 1) {
            this.data = res.data.data;
            console.log(this.data)
            if (res.data.data.dbs != undefined) {
              if (res.data.data.dbs.length > 0) {
                this.dbs = res.data.data.dbs
              }
            }
            this.modalVisible = true;
            this.dialogTitle = this.row.dialogTitle;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    handleClose() {
      this.row = ''
      this.loading = false
      this.modalVisible = false
      this.$emit('closeModal',false)
    },
  }
}

</script>

<style lang="scss" >
.test-detail{
    max-height: 500px;
    .tag-name{
        width: 10%;
    }
    .span-title{
        color: #999;
        width: 110px;
        text-align: right;
        display: inline-block;
    }
    .span-text{
        margin-left:10px;
        color: #505050;
    }
    .foot{
        padding-top: 20px;
        border-top: 1px solid #f4f4f4;
    }

}
.scale-result{
    padding-bottom: 20px;
    .c-r {
        text-align: right;
    }
    .p-title{
      color: #25bbdb;
      font-size: 14px;
      font-weight: bold;
    }
     .flex-start {
        display: flex;
        justify-content: flex-start
    }
    .borderee {
        border-top: 1px #eeeeee solid;
    }
    .c06 {
        color: #25bbdb;
    }
    .title {
        padding: 28px 24px 20px;
    }
    .tabTitle {
        height: 58px;
        line-height: 58px;
    }
    .tabTitle div {
        line-height: 58px;
    }
     .tabTitle :last-child {
        text-align: right;
    }
    .tabList {
        padding: 19px 0;
    }
    .tabList div {
        color: #333333;
    }
    .tabList div:last-child {
        text-align: right;
    }
    .explain div {
        // padding: 10px 0 0px;
    }
    .tab{
        margin-top: 31px;
    }

    // 富文本编辑器样式
    .quill-editor{
      border: 1px solid #ccc;
      .ql-toolbar.ql-snow{
        display: none;
        border:none !important
      }
      .ql-container.ql-snow{
        border:none !important
      }
    }
}
.title-all-bg{
    font-weight: bold;
}
.font-s16{
    font-size: 14px;
}
h5{
    margin: 10px 0px;
}
</style>

